<script setup lang="ts"></script>
<template>
  <footer class="tw-text-nl-yellow-100 tw-font-medium tw-text-sm">
    <div
      class="tw-relative tw-bg-nl-green-400 tw-mt-[50px] tw-pt-px md:tw-mt-[62px] tw-pb-[18px]"
    >
      <div
        class="tw-block md:tw-hidden tw-absolute tw-bottom-full md:-tw-mb-0.5 tw-inset-x-0 tw-h-[25px] min-[540px]:tw-h-[40px] tw-ml-[-2.5%] md:ml-0"
        style="
          background: url('/assets/images/home/footer-arc-mobile.svg') no-repeat
            0 0 / cover;
        "
      ></div>
      <div
        class="tw-hidden md:tw-block tw-absolute tw-bottom-full md:-tw-mb-0.5 tw-inset-x-0 tw-h-[62px]"
        style="
          background: url('/assets/images/home/footer-arc-desktop.svg') repeat-x
            50% 0 / auto 100%;
        "
      ></div>
      <div class="tw-container tw-mt-2 md:tw-mt-[-25px] tw-relative">
        <div class="tw-max-w-[197px] tw-mx-auto tw-mb-2">
          <img
            src="/assets/images/home/logo-white.svg"
            class="tw-w-full"
            alt="Nurture Life"
          />
        </div>
        <div class="tw-text-white tw-text-base tw-pl-0.5 tw-text-center">
          Growing up. Eating well.
        </div>
        <ul
          class="tw-text-[11px] tw-flex tw-justify-center tw-flex-wrap tw-pt-3 md:tw-pt-1 tw-gap-x-6 min-[375px]:tw-gap-x-14 md:tw-gap-x-6"
        >
          <li class="tw-pt-2">2023 Nurture Life, Inc.</li>
          <li class="tw-pt-2">
            <a class="tw-text-nl-yellow-100 hover:tw-underline" href="#">
              Terms
            </a>
            |
            <a class="tw-text-nl-yellow-100 hover:tw-underline" href="#">
              Privacy Policy
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
